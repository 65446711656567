import { createSlice } from '@reduxjs/toolkit'
import { STIKI_DEFAULT_COLOR } from '../../constants'

const initialState = {
  pagesPane: {
    isVisible: false,
  },
  network: {
    isFetching: false,
    hasError: false,
    errorMessage: '',
  },
  stikisMover: {
    isMoving: false,
    moveStart: {
      x: 0,
      y: 0,
    },
  },
  stikisResizer: {
    isResizing: false,
    resizeStart: {
      x: 0,
      y: 0,
    },
  },
  marquee: {
    isSelecting: false,
  },
  currentColor: STIKI_DEFAULT_COLOR,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showPagesPane: (state, action) => {
      state.pagesPane.isVisible = true
    },
    togglePagesPane: (state, action) => {
      state.pagesPane.isVisible = !state.pagesPane.isVisible
    },
    startStikiMove: (state, action) => {
      state.stikisMover.isMoving = true
      state.stikisMover.moveStart = {
        x: action.payload.x,
        y: action.payload.y,
      }
    },
    endStikiMove: (state, action) => {
      state.stikisMover = initialState.stikisMover
    },
    startResize: (state, action) => {
      state.stikisResizer.isResizing = true
      state.stikisResizer.resizeStart = {
        x: action.payload.x,
        y: action.payload.y,
      }
    },
    endResize: (state, action) => {
      state.stikisResizer = initialState.stikisResizer
    },
    savePending: (state, action) => {
      // console.log('savePending()')
      state.network.isFetching = true
      state.network.hasError = false
      state.network.errorMessage = ''
    },
    saveFulfilled: (state, action) => {
      // console.log('saveFulfilled()')
      state.network = initialState.network
    },
    saveRejected: (state, action) => {
      // console.log('saveRejected()')
      const errorMessage = action.payload?.error || 'unknown error'

      state.network.isFetching = false
      state.network.hasError = true
      state.network.errorMessage = errorMessage
    },
    setUICurrentColor: (state, action) => {
      state.currentColor = action.payload
    },
  },
})

export const {
  showPagesPane,
  togglePagesPane,
  startStikiMove,
  endStikiMove,
  startResize,
  endResize,
  setUICurrentColor,
  savePending,
  saveFulfilled,
  saveRejected,
} = uiSlice.actions

export default uiSlice.reducer

export const selectPagesPaneIsVisible = (state) => state.ui.pagesPane.isVisible

export const selectNetwork = (state) => state.ui.network

export const selectNetworkIsFetching = (state) => state.ui.network.isFetching

export const selectStikisMover = (state) => state.ui.stikisMover

export const selectStikisResizer = (state) => state.ui.stikisResizer

export const selectUICurrentColor = (state) => state.ui.currentColor
