import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import StikiContent from './StikiContent'

import {
  updateStikiAsync,
  destroyStikiAsync,
  setStikiSelected,
  setStikiUnselected,
  setStikiDirty,
  setStikiColor,
  selectStiki,
  selectCurrentStikiId,
} from '../redux/features/stikis/stikisSlice'
import {
  startStikiMove,
  startResize,
  setUICurrentColor,
  selectUICurrentColor,
} from '../redux/features/ui/uiSlice'

import { calculateColor } from '../redux/helpers'
import {
  STIKI_HALO_BORDER,
  STIKI_MIN_WIDTH,
  STIKI_MIN_HEIGHT,
} from '../redux/constants'

export default function Stiki({ id, resizerOffset, ignoreMouseMoves }) {
  const stiki = useSelector(selectStiki(id))
  const currentStikiId = useSelector(selectCurrentStikiId)
  const isCurrent = stiki.id === currentStikiId
  const currentUIColor = useSelector(selectUICurrentColor)

  resizerOffset = resizerOffset || { x: 0, y: 0 }
  if (!isCurrent) {
    resizerOffset = { x: 0, y: 0 }
  }

  ///////////////////////////////////////////////
  //
  // Hooks
  //

  const dispatch = useDispatch()
  const domRef = useRef(null)

  ///////////////////////////////////////////////
  //
  // Local state
  //

  const [isColoring, setIsColoring] = useState(false)
  const [hasColored, setHasColored] = useState(false)
  const [newColor, setNewColor] = useState(stiki.color)

  ///////////////////////////////////////////////
  //
  // Stiki event handlers
  //

  const handleClick = (e) => {
    // console.log('<Stiki/>.handleClick()')
    domRef?.current?.focus()
    if (!ignoreMouseMoves) {
      // console.log('...stopping propagation')
      e.stopPropagation()
    }
  }

  const handleMouseDown = (e) => {
    // console.log('<Stiki/>.handleStikiMouseDown()')

    if (stiki.isSelected) {
      if (e.shiftKey) {
        dispatch(setStikiUnselected(stiki.id))
      }
    } else {
      const retainSelection = e.shiftKey
      dispatch(setStikiSelected({ stikiId: stiki.id, retainSelection }))
      if (stiki.color !== currentUIColor) {
        dispatch(setUICurrentColor(stiki.color))
      }
    }
    dispatch(
      startStikiMove({
        x: e.pageX,
        y: e.pageY,
      })
    )
    e.preventDefault()
    e.stopPropagation()
  }

  ///////////////////////////////////////////////
  //
  // Resize button event handlers
  //

  const handleResizeMouseDown = (e) => {
    // console.log('<Stiki/>.handleResizeMouseDown()')

    dispatch(
      startResize({
        x: e.pageX,
        y: e.pageY,
      })
    )

    e.preventDefault()
    e.stopPropagation()
  }

  ///////////////////////////////////////////////
  //
  // Color button event handlers
  //

  const handleColorMouseDown = (e) => {
    // console.log('<Stiki/>.handleColorMouseDown()')
    if (!isColoring) {
      setIsColoring(true)
    } else {
      setHasColored(true)
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const handleColorMouseMove = (e) => {
    // ('<Stiki/>.handleColorMouseMove()')
    if (isColoring) {
      setHasColored(true)

      const { top, right, bottom, left } = e.target.getBoundingClientRect()
      const x = e.pageX - left
      const y = e.pageY - top
      const color = calculateColor(x, y)

      if (Boolean(color)) {
        setNewColor(color)
        dispatch(setUICurrentColor(color))
      }
      e.stopPropagation()
    }
  }

  const handleColorClick = (e) => {
    // console.log('<Stiki/>.handleColorClick()')
    if (hasColored) {
      e.stopPropagation()
      dispatch(setStikiColor({ stikiId: stiki.id, color: newColor }))
      dispatch(setStikiDirty(stiki.id))
      dispatch(updateStikiAsync(stiki.id))
      setNewColor(stiki.color)
      setIsColoring(false)
      setHasColored(false)
    } else {
      // e.stopPropagation()
      // dispatch(setStikiColor({ stikiId: stiki.id, color: newColor }))
      // dispatch(setStikiDirty(stiki.id))
      // dispatch(updateStikiAsync(stiki.id))
      // setNewColor(stiki.color)
      // setHasColored(false)
    }
  }

  ///////////////////////////////////////////////
  //
  // Destroy button event handlers
  //

  const handleAndStopPropagation = (e) => {
    // console.log('<Stiki/>.handleAndStopPropagation()')
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDestroyClicked = (e) => {
    // console.log('<Stiki/>.handleDestroyClicked')
    dispatch(destroyStikiAsync(id))
    e.preventDefault()
    e.stopPropagation()
  }

  ///////////////////////////////////////////////
  //
  // Formatting button event handlers
  //

  const handleBoldMouseDown = (e) => {
    // console.log('<Stiki />.handleBoldMouseDown()')
    document.execCommand('bold', false)
    e.stopPropagation()
  }

  const handleItalicMouseDown = (e) => {
    // console.log('<Stiki />.handleItalicMouseDown()')
    document.execCommand('italic', false)
    e.stopPropagation()
  }

  ///////////////////////////////////////////////
  //
  // Layout constants
  //

  const className =
    'stiki ' +
    [
      [stiki.isSelected, 'selected'],
      [isCurrent, 'current'],
    ]
      .map(([bool, cls]) => (bool ? cls : ''))
      .join(' ')

  // const className = stiki.isSelected ? 'stiki selected current' : 'stiki'

  const width = Math.max(STIKI_MIN_WIDTH, stiki.width + resizerOffset.x)
  const height = Math.max(STIKI_MIN_HEIGHT, stiki.height + resizerOffset.y)
  const haloStyle = stiki.isSelected
    ? {
        width: `${width + STIKI_HALO_BORDER}em`,
        height: `${height + STIKI_HALO_BORDER}em`,
      }
    : {}
  const colorCurveActive = isColoring ? 'active' : ''
  const color = hasColored ? newColor : stiki.color
  const dirtyMarker = stiki.isDirty
    ? '<span style="background: red;">*</span>'
    : ''

  const escapedText = stiki.text.replace(/</g, '&lt;').replace(/>/g, '&gt;')

  const stikiDebugText = `<pre>${escapedText}</pre><br/>${dirtyMarker}`
  // <br />id: ${stiki.id}<br />ignoreMouse: ${ignoreMouseMoves}`

  const debugDiv = (
    <div
      className='stikiDebugInfo'
      style={{ left: 0, bottom: 0, fontSize: '10px' }}
      dangerouslySetInnerHTML={{
        __html: stikiDebugText,
      }}
    ></div>
  )

  const tabIndex = '0'

  ///////////////////////////////////////////////
  //
  // Markup
  //

  return (
    <div
      className={className}
      id={`stiki_${stiki.id}`}
      style={{
        left: stiki.left_edge + 'em',
        top: stiki.top_edge + 'em',
        width: width + 'em',
        height: height + 'em',
      }}
      ref={domRef}
      tabIndex={tabIndex}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div className='halo' style={haloStyle}></div>
      <div className='stikiBackground' style={{ backgroundColor: color }}>
        <div className='contentMargin'></div>
        <StikiContent stikiId={stiki.id} ignoreMouseMoves={ignoreMouseMoves} />
      </div>
      <div className='resizeButtonContainer'>
        <div
          className='stikiButton resize curveL curveR'
          title='Resize'
          onMouseDown={handleResizeMouseDown}
        ></div>
      </div>
      <div className='closeButtonContainer'>
        <div
          className='stikiButton close curveL curveR'
          title='Delete'
          onMouseDown={handleAndStopPropagation}
          onClick={handleDestroyClicked}
        ></div>
      </div>
      <div className='colorButtonContainer'>
        <div
          className={`stikiButton color curveL ${colorCurveActive}`}
          title='Color'
          onMouseDown={handleColorMouseDown}
          onMouseMove={handleColorMouseMove}
          onClick={handleColorClick}
        ></div>
      </div>
      <button
        className='stikiButton bold'
        title='Bold'
        onMouseDown={handleBoldMouseDown}
      ></button>
      <button
        className='stikiButton italic curveR'
        title='Italic'
        onMouseDown={handleItalicMouseDown}
      ></button>
      {/* {debugDiv} */}
    </div>
  )
}
