import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAllPages } from '../redux/features/pages/pagesSlice'

import AddPage from './AddPage'
import PageLink from './PageLink'

export default function PagesPane({ isVisible, currentPageId }) {
  const pages = useSelector(selectAllPages)
  const pagesPaneClassName =
    'pagesPane pane bgSteelB' + (isVisible ? ' visible' : '')

  return (
    <div id='pagesPane' className={pagesPaneClassName}>
      <div className='paneShadow'></div>
      <AddPage />
      <div className='paneItem'>
        <h3 className='darkLinkBlue marginB5px'>Your Pages</h3>
      </div>
      <div id='paneContainer' className=''>
        <div id='pagesListDiv' className=''>
          <ul id='pagesIndex'>
            {pages && pages.length
              ? pages.map((pageId, index) => {
                  return (
                    <PageLink
                      key={`page-link-${pageId}`}
                      pageId={pageId}
                      currentPageId={currentPageId}
                    />
                  )
                })
              : 'No pages'}
          </ul>
        </div>
      </div>
    </div>
  )
}
