import React from 'react'
import AddStiki from './AddStiki'

export default function BottomPane({ pageId, pagesPaneIsVisible }) {
  const className = pagesPaneIsVisible ? 'pagesPaneShowing' : ''

  return (
    <div id='bottomPane' className={className} style={{ height: '48px' }}>
      <div id='bottomPaneContents'>
        <div className='hCenter'>
          <div className='centered400px txtC'>
            <AddStiki pageId={pageId} />
          </div>
        </div>
      </div>
    </div>
  )
}
