import * as client from '../../client'

// A function to fetch a list of pages from stikis
export function fetchPages(dispatch) {
  // console.log('creating promise to fetch pages in fetchPages')
  return client.get('/pages.json', dispatch)
}

// A function to create a page on the server
export function createPage(data, dispatch) {
  return client.post('/pages.json', data, dispatch)
}

// A function to fetch a list of pages from stikis
export function fetchPage(id, dispatch) {
  // console.log('creating promise to fetch pages in fetchPages')
  return client.get(`/pages/${id}.json`, dispatch)
}

// A function to update a page on the server
export function updatePage(pageId, data, dispatch) {
  return client.put(`/pages/${pageId}.json`, data, dispatch)
}

export function destroyPage(pageId, data, dispatch) {
  return client.destroy(`/pages/${pageId}.json`, data, dispatch)
}
