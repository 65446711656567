import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectAuthToken } from '../redux/features/pages/pagesSlice'

function Navigation(props) {
  const authToken = useSelector(selectAuthToken) || ''
  const formDom = useRef(null)

  const handleSignOutClick = (e) => {
    console.log('<Navigation/>.handleSignOutClick()')
    formDom.current.submit()
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div
      id='navigation'
      className='floatRight inlineBlock height100pct lineHeight35px'
    >
      <div className='menuLi'>
        <a className='menuLink' id='accountLink' href='/user'>
          Account
        </a>
      </div>
      <div className='menuLi'>
        <a
          className='menuLink'
          rel='nofollow'
          id='logoutLink'
          href='/user/sign_out'
          onClick={handleSignOutClick}
        >
          Sign out
        </a>
        <form
          className='hidden'
          method='post'
          action='/user/sign_out'
          ref={formDom}
        >
          <input name='_method' value='delete' type='hidden' />
          <input name='authenticity_token' value={authToken} type='hidden' />
        </form>
      </div>
      <div className='menuLi'>
        <a id='helpLink' className='menuLink' href='/help/pages/show.html'>
          Help
        </a>
      </div>
    </div>
  )
}

export default Navigation
