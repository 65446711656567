import React from 'react'

export default function SelectionBox({ marquee }) {
  let { left, top, width, height } = marquee

  // Deal with possible negative width and height
  if (width < 0) {
    left += width
    width = -width
  }

  if (height < 0) {
    top += height
    height = -height
  }

  return (
    <div
      className='selectionBox reactFixes'
      style={{
        left: `${left}em`,
        top: `${top}em`,
        width: `${width}em`,
        height: `${height}em`,
      }}
    ></div>
  )
}
