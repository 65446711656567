import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { createPageAsync } from '../redux/features/pages/pagesSlice'

export default function AddPage(props) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('New title')

  const pageData = () => {
    return {
      title,
    }
  }

  const handleChange = (e) => {
    // console.log('<AddPage />.handleChange()')
    setTitle(e.target.value)
  }

  const handleClick = (e) => {
    // console.log('<AddPage />.handleClick()')
    dispatch(createPageAsync(pageData()))
  }

  return (
    <div className='paneItem'>
      <h3 className='darkLinkBlue marginB5px'>New Page</h3>
      <div id='newPage'>
        <input id='newPageTitle' type='text' onChange={handleChange}></input>
        <button
          id='newPageSubmit'
          className='f14'
          aria-label='Create page'
          onClick={handleClick}
        >
          Create
        </button>
      </div>
    </div>
  )
}
