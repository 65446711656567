import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectPage,
  addPositionToPage,
  updatePageAsync,
  zoomByPageAsync,
} from '../redux/features/pages/pagesSlice'
import {
  addPositionToStikis,
  alignStikisTo,
  addSizeToStiki,
  updateDirtyStikisAsync,
  createStikiAsync,
  setStikisInMarqueeSelected,
  selectAllUnselectedStikisOnPage,
  setAllStikisOnPageUnselectedAsync,
  selectAllSelectedStikisOnPage,
  selectCurrentStikiId,
} from '../redux/features/stikis/stikisSlice'
import {
  selectUICurrentColor,
  selectStikisMover,
  selectStikisResizer,
  endStikiMove,
  endResize,
} from '../redux/features/ui/uiSlice'

import { pxToEm, clamp } from '../redux/helpers'
import {
  PAGE_BASE_FONT_SIZE,
  PAGE_ZOOM_PRESETS,
  SELECTION_NUDGE_DIST,
  PAGE_NUDGE_DIST,
  PAGE_SHIFT_NUDGE_DIST,
  STIKI_MIN_LEFT_EDGE,
  STIKI_MAX_LEFT_EDGE,
  STIKI_MIN_TOP_EDGE,
  STIKI_MAX_TOP_EDGE,
} from '../redux/constants'

import Stiki from './Stiki'
import StikisMover from './StikisMover'
import SelectionBox from './SelectionBox'

export default function StikisField({ pageId }) {
  const dispatch = useDispatch()
  const domRef = useRef(null)

  useEffect(() => {
    domRef?.current?.focus()
  }, [pageId])

  ///////////////////////////////////////////////
  //
  // Selectors
  //

  const page = useSelector(selectPage(pageId))
  const zoom = page.zoom
  const selectedStikis = useSelector(selectAllSelectedStikisOnPage(pageId))
  const unselectedStikis = useSelector(selectAllUnselectedStikisOnPage(pageId))
  const currentColor = useSelector(selectUICurrentColor)
  const stikisMover = useSelector(selectStikisMover)
  const stikisResizer = useSelector(selectStikisResizer)
  const currentStiki = useSelector(selectCurrentStikiId)

  ///////////////////////////////////////////////
  //
  // Local state
  //

  const [isMoving, setIsMoving] = useState(false)
  const [hasMoved, setHasMoved] = useState(false)
  const [moveStart, setMoveStart] = useState({ x: 0, y: 0 })
  const [moveOffset, setMoveOffset] = useState({ x: 0, y: 0 })

  const [hasResized, setHasResized] = useState(false)
  const [resizerOffset, setResizerOffset] = useState({ x: 0, y: 0 })

  const [selectionPosition, setSelectionPosition] = useState({ x: 0, y: 0 })
  const [selectionHasMoved, setSelectionHasMoved] = useState(false)

  const [isMarqueeSelecting, setIsMarqueeSelecting] = useState(false)
  const [marquee, setMarquee] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  })

  ///////////////////////////////////////////////
  //
  // Helpers
  //

  const focusDomRef = () => {
    // console.log('<StikisField />.focusDomRef()')
    domRef.current.focus()
  }

  const chuckSelection = (direction) => {
    const [side, position] = {
      ArrowLeft: ['RIGHT', STIKI_MIN_LEFT_EDGE],
      ArrowRight: ['LEFT', STIKI_MAX_LEFT_EDGE],
      ArrowUp: ['BOTTOM', STIKI_MIN_TOP_EDGE],
      ArrowDown: ['TOP', STIKI_MAX_TOP_EDGE],
    }[direction]

    dispatch(
      alignStikisTo({
        stikiIds: selectedStikis,
        side,
        position,
      })
    )
    dispatch(updateDirtyStikisAsync(pageId))
  }

  const moveSelection = (xDir, yDir, moveDist) => {
    // console.log('<StikisField />.moveSelection()')

    dispatch(
      addPositionToStikis({
        stikiIds: selectedStikis,
        position: {
          x: xDir * moveDist,
          y: yDir * moveDist,
        },
      })
    )
    dispatch(updateDirtyStikisAsync(pageId))
  }

  const movePage = (xDir, yDir, moveDist) => {
    // console.log('<StikisField />.movePage()')

    dispatch(
      addPositionToPage({
        pageId: page.id,
        position: {
          x: xDir * moveDist,
          y: yDir * moveDist,
        },
      })
    )
    dispatch(updatePageAsync(page.id))
  }

  ///////////////////////////////////////////////
  //
  // Event handlers
  //

  const handleKeyDown = (e) => {
    // console.log('<StikisField />.handleKeyDown() e.altKey:', e.altKey)

    const directions = {
      ArrowLeft: [-1, 0],
      ArrowRight: [1, 0],
      ArrowUp: [0, -1],
      ArrowDown: [0, 1],
    }
    if (Object.keys(directions).includes(e.key)) {
      const xDir = directions[e.key][0]
      const yDir = directions[e.key][1]
      let dist = SELECTION_NUDGE_DIST

      if (selectedStikis.length === 0) {
        dist = PAGE_NUDGE_DIST
        if (e.shiftKey) {
          dist = PAGE_SHIFT_NUDGE_DIST
        }
        movePage(xDir, yDir, dist)
      } else if (e.shiftKey) {
        chuckSelection(e.key)
        e.preventDefault()
        e.stopPropagation()
      } else {
        moveSelection(xDir, yDir, dist)
      }
    }
  }

  const handleMouseDown = (e) => {
    focusDomRef()

    if (e.shiftKey) {
      // console.log('<StikisField />.handleMouseDown() for => marquee')
      setIsMarqueeSelecting(true)

      // Figure out the marquee start relative to page origin
      const domRect = domRef.current.getBoundingClientRect()
      const startX = e.pageX - domRect.width / 2
      const startY = e.pageY - domRect.height / 2

      setMarquee({
        left: pxToEm(startX, page.zoom) - page.left_edge,
        top: pxToEm(startY, page.zoom) - page.top_edge,
        width: 0,
        height: 0,
      })
    } else {
      // console.log('<StikisField />.handleMouseDown() for => pageMover')
      setIsMoving(true)
      setMoveStart({ x: e.pageX, y: e.pageY })
    }

    e.stopPropagation()
    e.preventDefault()
  }

  const handleMouseMove = (e) => {
    if (stikisResizer.isResizing) {
      // console.log('<StikisField />.handleMouseMove() for => stikisResizer')
      setHasResized(true)
      setResizerOffset({
        x: pxToEm(e.pageX - stikisResizer.resizeStart.x, zoom),
        y: pxToEm(e.pageY - stikisResizer.resizeStart.y, zoom),
      })
    } else if (stikisMover.isMoving) {
      // console.log('<StikisField />.handleMouseMove() for => stikisMover')
      setSelectionPosition({
        x: e.pageX - stikisMover.moveStart.x,
        y: e.pageY - stikisMover.moveStart.y,
      })
      setSelectionHasMoved(true)
    } else if (isMarqueeSelecting) {
      // console.log('<StikiField />.handleMouseMove() for =>  marquee')
      // Figure out the marquee start relative to page origin
      const domRect = domRef.current.getBoundingClientRect()
      const endX = e.pageX - domRect.width / 2
      const endY = e.pageY - domRect.height / 2

      setMarquee({
        ...marquee,
        width: pxToEm(endX, page.zoom) - page.left_edge - marquee.left,
        height: pxToEm(endY, page.zoom) - page.top_edge - marquee.top,
      })
    } else if (isMoving) {
      // console.log('<StikisField />.handleMouseMove() for => pageMover')
      setHasMoved(true)
      setMoveOffset({
        x: pxToEm(e.pageX - moveStart.x, page.zoom),
        y: pxToEm(e.pageY - moveStart.y, page.zoom),
      })
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const handleMouseUp = (e) => {
    if (stikisResizer.isResizing) {
      // console.log('<StikisField />.handleMouseUp() for => stikisResizer')
      if (hasResized) {
        dispatch(
          addSizeToStiki({
            stikiId: currentStiki,
            size: resizerOffset,
          })
        )
        dispatch(updateDirtyStikisAsync(pageId))
      }
      setResizerOffset({ x: 0, y: 0 })
      dispatch(endResize())
      setHasResized(false)
    } else if (stikisMover.isMoving) {
      // console.log('<StikisField />.handleMouseUp() for => stikisMover')

      if (selectionHasMoved) {
        dispatch(
          addPositionToStikis({
            stikiIds: selectedStikis,
            position: {
              x: pxToEm(selectionPosition.x, page.zoom),
              y: pxToEm(selectionPosition.y, page.zoom),
            },
          })
        )
      }
      dispatch(updateDirtyStikisAsync(pageId))
      dispatch(endStikiMove())

      setSelectionPosition({ x: 0, y: 0 })
      setSelectionHasMoved(false)
    } else if (isMarqueeSelecting) {
      // console.log('<StikisField />.handleMouseUp() for => marquee')
      dispatch(
        setStikisInMarqueeSelected({
          pageId,
          marquee,
          retainSelection: e.shiftKey,
        })
      )
      dispatch(updateDirtyStikisAsync(pageId))
      setIsMarqueeSelecting(false)
      setMarquee({ left: 0, top: 0, width: 0, height: 0 })
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const handleClick = (e) => {
    // console.log('<StikisField />.handleClick()')
    setIsMoving(false)
    if (hasMoved) {
      // e.stopPropagation()
      dispatch(
        addPositionToPage({
          pageId: page.id,
          position: moveOffset,
        })
      )
      dispatch(updatePageAsync(page.id))
    }
    setMoveStart({ x: 0, y: 0 })
    setMoveOffset({ x: 0, y: 0 })
    setHasMoved(false)
    dispatch(setAllStikisOnPageUnselectedAsync(pageId))
    // e.stopPropagation()
  }

  const handleDoubleClick = (e) => {
    if (e.target.id !== 'panBackground') {
      return
    }

    // console.log('<StikisField />.handleDoubleClick()')

    const x = e.pageX
    const y = e.pageY
    const { top, right, bottom, left } = e.target.getBoundingClientRect()

    const fieldCentreX = left + (right - left) / 2
    const fieldCentreY = top + (bottom - top) / 2

    const leftEdge = pxToEm(x - fieldCentreX, page.zoom) - 1.5
    const topEdge = pxToEm(y - fieldCentreY, page.zoom) - 1.4

    const stikiData = {
      page_id: page.id,
      stiki: {
        left_edge: leftEdge,
        top_edge: topEdge,
        height: 7,
        width: 15,
        color: currentColor,
      },
    }
    dispatch(createStikiAsync(stikiData))
  }

  const handleWheel = (e) => {
    const minZoom = PAGE_ZOOM_PRESETS[0]
    const maxZoom = PAGE_ZOOM_PRESETS[PAGE_ZOOM_PRESETS.length - 1]

    const percentageChange = 1 + e.deltaY / 100
    const zoomDelta = zoom - zoom * percentageChange
    const clampedDelta = clamp(Math.abs(zoomDelta), 0.01, 0.5)
    const zoomDir = zoomDelta < 0 ? -1 : 1
    const newZoom =
      Math.round(100 * clamp(zoom + clampedDelta * zoomDir, minZoom, maxZoom)) /
      100
    const zoomBy = newZoom - zoom

    // Figure out the zoom offset
    const domRect = domRef.current.getBoundingClientRect()
    const zoomX = e.pageX - domRect.width / 2
    const zoomXAfter = (zoomX * newZoom) / zoom
    const zoomXAdjust = pxToEm(zoomX - zoomXAfter, newZoom)

    const zoomY = e.pageY - domRect.height / 2
    const zoomYAfter = (zoomY * newZoom) / zoom
    const zoomYAdjust = pxToEm(zoomY - zoomYAfter, newZoom)

    if (zoomBy !== 0) {
      dispatch(
        zoomByPageAsync({
          pageId,
          zoomBy,
          position: { x: zoomXAdjust, y: zoomYAdjust },
        })
      )
    }
  }

  ///////////////////////////////////////////////
  //
  // Layout constants
  //

  const className = isMarqueeSelecting ? 'selecting' : ''
  const leftEdge = page.left_edge + moveOffset.x
  const topEdge = page.top_edge + moveOffset.y
  const tabIndex = '0'

  ///////////////////////////////////////////////
  //
  // Markup
  //

  return (
    <div
      id='stikisField'
      className={className}
      style={{ fontSize: PAGE_BASE_FONT_SIZE + 'px' }}
      ref={domRef}
      tabIndex={tabIndex}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onWheel={handleWheel}
      onKeyDown={handleKeyDown}
    >
      <div id='stikisFieldCenter'>
        <div id='zoomOrigin' style={{ fontSize: page.zoom * 100 + '%' }}>
          <div id='zoomInMarker' className='zoomMarker'>
            <div className='t'></div>
            <div className='l'></div>
            <div className='r'></div>
            <div className='b'></div>
          </div>
          <div
            id='pageMover'
            className='cursorWrap'
            style={{ left: 0, top: 0 }}
          ></div>
          <div
            className='page'
            id={'page_' + page.id}
            style={{ left: leftEdge + 'em', top: topEdge + 'em' }}
          >
            <div id='panBackground'></div>
            {unselectedStikis.map((stiki_id) => {
              return (
                <Stiki
                  key={`stiki-${stiki_id}`}
                  id={stiki_id}
                  ignoreMouseMoves={isMarqueeSelecting}
                />
              )
            })}
            <StikisMover
              pageId={pageId}
              position={selectionPosition}
              resizerOffset={resizerOffset}
              ignoreMouseMoves={isMarqueeSelecting}
            />
            {isMarqueeSelecting && <SelectionBox marquee={marquee} />}
          </div>
        </div>
      </div>
    </div>
  )
}
