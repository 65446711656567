import * as client from '../../client'

// A function to create a stiki on a page on the server
export function createStiki(pageId, data, dispatch) {
  return client.post(`/pages/${pageId}/stikis.json`, data, dispatch)
}

export function updateStiki(stikiId, data, dispatch) {
  return client.put(`/stikis/${stikiId}.json`, data, dispatch)
}

export function updateStikisGroup(pageId, data, dispatch) {
  return client.put(`/pages/${pageId}/stikis/update_group.json`, data, dispatch)
}

export function destroyStiki(stikiId, data, dispatch) {
  return client.destroy(`/stikis/${stikiId}.json`, data, dispatch)
}
