import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectPage } from '../redux/features/pages/pagesSlice'

import StikisField from './StikisField'
import TopMenu from './TopMenu'
import PageMenu from './PageMenu'
import PagesPane from './PagesPane'
import BottomPane from './BottomPane'

import { selectPagesPaneIsVisible } from '../redux/features/ui/uiSlice'
import { selectPageExists } from '../redux/features/pages/pagesSlice'
import { selectNetworkIsFetching } from '../redux/features/ui/uiSlice'

export default function Page({ match }) {
  const pageId = Number(match.params.id)
  const page = useSelector(selectPage(pageId))

  const pageExists = useSelector(selectPageExists(pageId))
  const pagesPaneIsVisible = useSelector(selectPagesPaneIsVisible)
  const isFetching = useSelector(selectNetworkIsFetching)
  const doShowPagesPane = pagesPaneIsVisible || (!pageExists && !isFetching)

  useEffect(() => {
    document.title = Boolean(page) ? `${page.title}` : document.title
  })

  return (
    <div id='page_container'>
      {Boolean(page) && <StikisField pageId={pageId} />}
      <TopMenu pageId={pageId} />
      <PageMenu pageId={pageId} pagesPaneIsVisible={doShowPagesPane} />
      <PagesPane isVisible={doShowPagesPane} currentPageId={pageId} />
      <BottomPane pageId={pageId} pagesPaneIsVisible={doShowPagesPane} />
    </div>
  )
}
