import React from 'react'
import { useDispatch } from 'react-redux'

import { destroyPageAsync } from '../redux/features/pages/pagesSlice'

export default function DeletePage({ pageId }) {
  const dispatch = useDispatch()

  const handleClick = (e) => {
    // console.log('<DeletePage />.handleClicked()')
    dispatch(destroyPageAsync(pageId))
    e.stopPropagation()
  }

  return (
    <button
      className='pageLinkDelete'
      title='Delete page'
      onClick={handleClick}
    ></button>
  )
}
