import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectPageTitle } from '../redux/features/pages/pagesSlice'

import DeletePage from './DeletePage'

export default function PageLink({ pageId, currentPageId }) {
  const title = useSelector(selectPageTitle(pageId))

  const className = pageId !== currentPageId ? 'pageLink' : 'pageLink disabled'

  return (
    <li id={`pageLink_${pageId}`} className={className}>
      <Link to={`/app/pages/${pageId}`}>{title}</Link>
      <DeletePage pageId={pageId} />
    </li>
  )
}
