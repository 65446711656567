import React from 'react'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import { history } from '../redux/store'
import Home from '../components/Home'
import Test from '../components/Test'
import Page from '../components/Page'

export default function App() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/app' exact component={Home} />
        <Route path='/app/pages/:id' exact component={Page} />
        <Route path='/app/pages' exact component={Page} />
        <Route path='/app/test' exact component={Test} />
      </Switch>
    </ConnectedRouter>
  )
}
