import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'

import {
  setStikiContent,
  setStikiDirty,
  updateStikiAsync,
  selectStiki,
} from '../redux/features/stikis/stikisSlice'

import {
  selectStikisMover,
  selectStikisResizer,
} from '../redux/features/ui/uiSlice'

export default function StikiContent({ stikiId, ignoreMouseMoves }) {
  const stiki = useSelector(selectStiki(stikiId))
  const stikisMover = useSelector(selectStikisMover)
  const stikisResizer = useSelector(selectStikisResizer)

  ///////////////////////////////////////////////
  //
  // Hooks
  //

  const dispatch = useDispatch()
  const contentEditableText = useRef(stiki.text)

  ///////////////////////////////////////////////
  //
  // ContentEditable event handlers
  //

  const handleMouseDown = (e) => {
    // console.log('<Stiki/>.handleContentMouseDown()')
    if (stiki.isSelected) {
      e.stopPropagation()
    }
  }

  const handleMouseMove = (e) => {
    // console.log('<Stiki/>.handleContentMouseMove()')
    if (
      !stikisMover.isMoving &&
      !ignoreMouseMoves &&
      !stikisResizer.isResizing
    ) {
      e.stopPropagation()
    }
  }

  const handleMouseUp = (e) => {
    // console.log('<Stiki/>.handleContentMouseUp()')
    if (!stikisMover.isMoving && !ignoreMouseMoves) {
      e.stopPropagation()
    }
  }

  const handleClick = (e) => {
    e.stopPropagation()
  }

  const handleContentChange = (e) => {
    contentEditableText.current = e.target.value
    // console.log('<Stiki/>.handleContentChange()\n', contentEditableText.current)
    if (!stiki.isDirty) {
      dispatch(setStikiDirty(stiki.id))
    }
    // e.stopPropagation(e)
  }

  const handleContentBlur = (e) => {
    // console.log('<Stiki/>.handleContentBlur()')
    const sanitizedHtml = sanitizeHtml(
      contentEditableText.current,
      sanitizeConf
    )
    contentEditableText.current = sanitizedHtml
    if (stiki.text !== sanitizedHtml) {
      dispatch(setStikiContent({ stiki_id: stiki.id, text: sanitizedHtml }))
      dispatch(updateStikiAsync(stiki.id))
    }
  }

  const handleKeyDown = (e) => {
    // console.log('<StikiContent/>.handleContentKeyDown()')
    e.stopPropagation()
  }

  ///////////////////////////////////////////////
  //
  // Layout constants
  //

  const sanitizeConf = {
    allowedTags: ['a', 'b', 'i', 'em', 'strong', 'p', 'h1', 'br', 'div'],
    allowedAttributes: { a: ['href'] },
  }

  return (
    <div
      className='contentContainer'
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <ContentEditable
        className='stikiContent'
        style={{ fontSize: '100%' }}
        html={contentEditableText.current}
        disabled={false}
        onChange={handleContentChange}
        onBlur={handleContentBlur}
      />
    </div>
  )
}
