import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { fetchPages, createPage, updatePage, destroyPage } from './pagesAPI'
import {
  addStiki,
  loadStikisData,
  selectAllStikisOnPage,
  removeAllStikisForPage,
} from '../stikis/stikisSlice'
import { history } from '../../store'

import {
  PAGE_DEFAULT_TITLE,
  PAGE_DEFAULT_LEFT_EDGE,
  PAGE_DEFAULT_TOP_EDGE,
  PAGE_DEFAULT_ZOOM,
  PAGE_ZOOM_PRESETS,
  PAGE_BASE_FONT_SIZE,
} from '../../constants'

const initialState = {
  isFetching: false,
  didValidate: false,
  lastUpdated: null,
  allIds: [],
  byIds: {},
  authToken: null,
}

export const fetchPagesAsync = () => async (dispatch, getState) => {
  const json = await fetchPages(dispatch)
  batch(() => {
    dispatch(loadStikisData(json))
    dispatch(loadPagesData(json))
  })
}

export const createPageAsync = (data) => async (dispatch, getState) => {
  const state = getState()
  data.authenticity_token = selectAuthToken(state)

  const json = await createPage(data, dispatch)

  let stikis
  try {
    stikis = json.page.stikis
  } catch (error) {
    return
  }

  batch(() => {
    stikis.forEach((e) => {
      dispatch(addStiki(e))
    })
    dispatch(addPage(json))
    history.push(`/app/pages/${json.page.id}`)
  })
}

export const zoomByPageAsync = (data) => async (dispatch, getState) => {
  const pageId = data.pageId
  const zoomBy = data.zoomBy
  const position = data.position

  batch(() => {
    dispatch(addPositionToPage({ pageId, position }))
    dispatch(zoomByPage({ pageId, zoomBy }))
  })
  dispatch(updatePageAsync(pageId))
}

export const updatePageAsync = (pageId) => async (dispatch, getState) => {
  const state = getState()
  const authenticity_token = selectAuthToken(state)
  const page = selectPage(pageId)(state)

  const updateData = {
    page,
    authenticity_token,
  }

  const json = await updatePage(pageId, updateData, dispatch)

  let pageData
  try {
    pageData = json.page
  } catch (error) {
    return
  }

  // TODO update the state for e.g. whitelisted title?
}

export const destroyPageAsync = (pageId) => async (dispatch, getState) => {
  const state = getState()
  const pageToDelete = state.pages.byIds[pageId]
  const stikisToDelete = selectAllStikisOnPage(pageId)(state)

  const destroyData = {
    authenticity_token: selectAuthToken(state),
  }

  // Optimistically remove the page and all associated stikis.
  // TODO: Roll back if the request fails
  batch(() => {
    dispatch(removePage(pageId))
    dispatch(removeAllStikisForPage(pageId))
  })

  history.push(`/app/pages/`)

  const json = await destroyPage(pageId, destroyData, dispatch)
}

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    addPage: (state, action) => {
      const page = action.payload.page
      delete page.stikis
      const pageId = page.id
      state.allIds.unshift(pageId)
      state.byIds[pageId] = page
    },
    loadPagesData: (state, action) => {
      // const byIds = {}
      const pages = action.payload.pages
      state.byIds = {}
      state.allIds = pages.map((e) => {
        const page = e.page
        delete page.stikis
        state.byIds[page.id] = page
        return page.id
      })
      state.isFetching = false
      state.hasLoaded = true
      state.authToken = action.payload.auth_token
    },
    zoomInPage: (state, action) => {
      const pageId = action.payload
      const page = state.byIds[pageId]
      page.zoom =
        PAGE_ZOOM_PRESETS.filter((e) => e > page.zoom).sort()[0] || page.zoom
    },
    zoomOutPage: (state, action) => {
      const pageId = action.payload
      const page = state.byIds[pageId]
      page.zoom =
        PAGE_ZOOM_PRESETS.filter((e) => e < page.zoom)
          .sort()
          .reverse()[0] || page.zoom
    },
    zoomByPage: (state, action) => {
      const pageId = action.payload.pageId
      const zoomBy = action.payload.zoomBy
      state.byIds[pageId].zoom += zoomBy
    },
    addPositionToPage: (state, action) => {
      const pageId = action.payload.pageId
      const position = action.payload.position

      state.byIds[pageId].left_edge += position.x
      state.byIds[pageId].top_edge += position.y
    },
    setPageTitle: (state, action) => {
      const pageId = action.payload.pageId
      const title = action.payload.title

      state.byIds[pageId].title = title
    },
    removePage: (state, action) => {
      const pageId = action.payload
      const idx = state.allIds.indexOf(pageId)
      state.allIds.splice(idx, 1)
      delete state.byIds[pageId]
    },
  },
  extraReducers: {},
})

export const {
  addPage,
  zoomInPage,
  zoomOutPage,
  zoomByPage,
  loadPagesData,
  addStikiToPage,
  addPositionToPage,
  setPageTitle,
  removePage,
} = pagesSlice.actions

export default pagesSlice.reducer

export const selectPage = (id) => (state) => state.pages.byIds[id]

export const selectPageZoom = (id) => (state) => state.pages.byIds[id].zoom

export const selectPageTitle = (id) => (state) => state.pages.byIds[id]?.title

export const selectAllPages = (state) => state.pages.allIds

export const selectPagesIsFetching = (state) => state.pages.isFetching

export const selectPageExists = (id) => (state) =>
  state.pages.allIds.includes(id)

export const selectAuthToken = (state) => state.pages.authToken
