export const pxToEm = (px, zoom) => {
  return px / (9.8 * zoom)
}

export const emToPx = (em, zoom) => {
  return 9.8 * em * zoom
}

export const clamp = (num, min, max) => {
  return Math.max(min, Math.min(num, max))
}

export const calculateColor = (x, y) => {
  // console.log('calculateColor() x, y:', Math.floor(x), Math.floor(y))

  const FAN_RADIUS = 48
  x = FAN_RADIUS - x
  y = FAN_RADIUS - y
  const length = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))

  // console.log('    x, y, len:', Math.floor(x), Math.floor(y), length)

  // Return if the mouseup was outside the radius of the fan.
  if (length > FAN_RADIUS) {
    return
  }

  let r = 0
  let g = 0
  let b = 0

  let angle = Math.atan(x / y)
  angle = 90 - (angle * 180) / Math.PI

  // console.log('    angle:', angle)

  if (angle < 9) {
    // dark blue to purple
    b = 255
    r = 128 + (127 * angle) / 9
  } else if (angle < 27) {
    // purple to red
    r = 255
    b = 255 - (255 * (angle - 9)) / 18
  } else if (angle < 45) {
    // red to yellow
    r = 255
    g = (255 * (angle - 27)) / 18
  } else if (angle < 63) {
    // yellow to green
    g = 255
    r = 255 - (255 * (angle - 45)) / 18
  } else if (angle < 81) {
    // green to light blue
    g = 255
    b = (255 * (angle - 63)) / 18
  } else if (angle < 90) {
    // light blue to dark blue
    b = 255
    g = 255 - (127 * (angle - 81)) / 9
  }

  // console.log('    r, g, b:', r, g, b)

  // Tint the color depending on how far from the center the user clicked.
  const tint = Math.max(1 - (length - 10) / FAN_RADIUS, 0)
  const white = 255 * (1 - tint)

  // console.log('    tint, white:', tint, white)

  let color =
    'rgb(' +
    Math.round(r * tint + white) +
    ',' +
    Math.round(g * tint + white) +
    ',' +
    Math.round(b * tint + white) +
    ')'

  // console.log('    color:', color)

  return color
}
