import React from 'react'
import { Link } from 'react-router-dom'

function Test(props) {
  const match = props.match

  return (
    <div className='lightBox'>
      <div className='message'>
        <h1>Test</h1>
        <p>
          <strong>match:</strong>
          {match}
        </p>
      </div>
    </div>
  )
}

export default Test
