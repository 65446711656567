import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import store from '../redux/store'
import { fetchPagesAsync } from '../redux/features/pages/pagesSlice'
import App from '../components/App'

// const unsubscribe = store.subscribe(() => console.log(store.getState()))
// store.dispatch(fetchPages()).then(() => console.log(store.getState()))
// unsubscribe()
store.dispatch(fetchPagesAsync())

document.addEventListener('DOMContentLoaded', () => {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.body.appendChild(document.createElement('div'))
  )
})
