import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllSelectedStikisOnPage } from '../redux/features/stikis/stikisSlice'

import Stiki from './Stiki'

export default function StikisMover({
  pageId,
  position,
  resizerOffset,
  ignoreMouseMoves,
}) {
  const selectedStikis = useSelector(selectAllSelectedStikisOnPage(pageId))

  return (
    <div
      id='stikisMover'
      className='cursorWrap'
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      {selectedStikis.map((stiki_id) => {
        return (
          <Stiki
            key={`stiki-${stiki_id}`}
            id={stiki_id}
            resizerOffset={resizerOffset}
            ignoreMouseMoves={ignoreMouseMoves}
          />
        )
      })}
    </div>
  )
}
