import React from 'react'

import { Link } from 'react-router-dom'

function Home(props) {
  return (
    <div className='lightBox'>
      <div className='message'>
        <h1>Home</h1>
      </div>
    </div>
  )
}

export default Home
