import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  zoomInPage,
  zoomOutPage,
  updatePageAsync,
  selectPage,
} from '../redux/features/pages/pagesSlice'
import { togglePagesPane } from '../redux/features/ui/uiSlice'

import SavingIndicator from './SavingIndicator'

import ZoomOut from 'images/zoom_out.png'
import ZoomIn from 'images/zoom_in.png'

export default function PageMenu({ pageId, pagesPaneIsVisible }) {
  const dispatch = useDispatch()
  const page = useSelector(selectPage(pageId))

  const handleShowPagesClick = (e) => {
    // console.log('<PageMenu />.handleShowPagesClick()')
    dispatch(togglePagesPane())
  }

  const handleZoomOutClick = (e) => {
    // console.log('<PageMenu />.handleZoomOutClick()')
    dispatch(zoomOutPage(pageId))
    dispatch(updatePageAsync(pageId))
  }

  const handleZoomInClick = (e) => {
    // console.log('<PageMenu />.handleZoomInClick()')
    dispatch(zoomInPage(pageId))
    dispatch(updatePageAsync(pageId))
  }

  const pageMenuClassName =
    'menuStrip' + (pagesPaneIsVisible ? ' paneActive' : '')

  const zoomText =
    Boolean(page) && !isNaN(page.zoom) ? `${Math.round(page.zoom * 100)}%` : ''

  return (
    <ul id='pageMenu' className={pageMenuClassName}>
      <li className='topShadow'>&nbsp;</li>
      <li
        id='pagesLinkLi'
        title='Show pages'
        className='pageMenuItem hover relative bR1px'
        onClick={handleShowPagesClick}
      ></li>
      {Boolean(page) && (
        <li
          id='zoomOutImg'
          className='pageMenuItem hover relative stopMousedown'
          onClick={handleZoomOutClick}
        >
          <img src={ZoomOut} alt='Zoom out' title='Zoom out' />
        </li>
      )}
      {Boolean(page) && (
        <li
          id='zoomValue'
          className='pageMenuItem relative txtC minW40px stopMousedown'
        >
          {zoomText}
        </li>
      )}
      {Boolean(page) && (
        <li
          id='zoomInImg'
          className='pageMenuItem hover relative bR1px stopMousedown'
          onClick={handleZoomInClick}
        >
          <img src={ZoomIn} alt='Zoom in' title='Zoom in' />
        </li>
      )}
      <SavingIndicator />
    </ul>
  )
}
