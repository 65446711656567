import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import {
  createStiki,
  updateStiki,
  updateStikisGroup,
  destroyStiki,
} from './stikisAPI'

import { selectAuthToken } from '../pages/pagesSlice'

import { clamp } from '../../helpers'

import {
  STIKI_DEFAULT_COLOR,
  STIKI_HALO_BORDER,
  STIKI_DEFAULT_LEFT_EDGE,
  STIKI_DEFAULT_TOP_EDGE,
  STIKI_DEFAULT_WIDTH,
  STIKI_DEFAULT_HEIGHT,
  STIKI_DEFAULT_Z_INDEX,
  STIKI_MIN_LEFT_EDGE,
  STIKI_MAX_LEFT_EDGE,
  STIKI_MIN_TOP_EDGE,
  STIKI_MAX_TOP_EDGE,
  STIKI_MIN_WIDTH,
  STIKI_MIN_HEIGHT,
} from '../../constants'

const initialState = {
  isFetching: false,
  didValidate: false,
  lastUpdated: null,
  allIds: [],
  byIds: {},
  currentStiki: null,
}

export const createStikiAsync = (data) => async (dispatch, getState) => {
  const state = getState()
  const pageId = data.page_id
  const stikiData = {
    stiki: { ...data.stiki },
    authenticity_token: selectAuthToken(state),
  }

  const json = await createStiki(pageId, stikiData, dispatch)

  json.stiki.isSelected = true

  batch(() => {
    dispatch(setAllStikisOnPageUnselected(pageId))
    dispatch(addStiki(json))
  })
}

export const updateStikiAsync = (stikiId) => async (dispatch, getState) => {
  const state = getState()
  const authenticity_token = selectAuthToken(state)
  const stiki = selectStiki(stikiId)(state)
  const pageId = stiki.pageId

  const updateData = {
    stiki,
    authenticity_token,
  }

  const json = await updateStiki(stikiId, updateData, dispatch)

  let stikiData
  try {
    stikiData = json.stiki
  } catch (error) {
    return
  }

  // TODO update the state for e.g. whitelisted text?
  // console.log('dispatching setStikiClean from updateStikiAsync')
  dispatch(setStikiClean(stikiData.id))
}

export const updateDirtyStikisAsync =
  (pageId) => async (dispatch, getState) => {
    const state = getState()
    const stikis = selectDirtyStikisOnPage(pageId)(state)
    const authenticity_token = selectAuthToken(state)

    const updates = {}

    stikis.forEach((stikiId, idx) => {
      const stiki = state.stikis.byIds[stikiId]
      updates[idx] = stiki
    })

    const updateData = {
      updates,
      authenticity_token,
    }

    const json = await updateStikisGroup(pageId, updateData, dispatch)

    // TODO: The json could probably be more descriptive?

    // let stikisData
    // try {
    //   stikisData = json.stikis
    // } catch (error) {
    //   return
    // }

    stikis.forEach((stikiId) => {
      // console.log('dispatching setStikiClean from updateDirtyStikisAsync')
      dispatch(setStikiClean(stikiId))
    })
  }

export const setStikiUnselectedAsync =
  (stikiId) => async (dispatch, getState) => {
    const state = getState()
    const stiki = state.stikis.byIds[stikiId]
    if (stiki.isDirty) {
      dispatch(updateStikiAsync(stiki.id))
    }
    dispatch(setStikiUnselected(stikiId))
  }

export const setAllStikisOnPageUnselectedAsync =
  (pageId) => async (dispatch, getState) => {
    const state = getState()
    state.stikis.allIds.forEach((stikiId) => {
      const stiki = state.stikis.byIds[stikiId]
      if (stiki.page_id === pageId) {
        if (stiki.isDirty) {
          dispatch(updateStikiAsync(stiki.id))
        }
      }
    })
    dispatch(setAllStikisOnPageUnselected(pageId))
  }

export const destroyStikiAsync = (stikiId) => async (dispatch, getState) => {
  const state = getState()
  const stikiToDelete = state.stikis.byIds[stikiId]

  const destroyData = {
    authenticity_token: selectAuthToken(state),
  }

  // Optimistically remove the stiki.
  // TODO: Add back if request fails
  dispatch(removeStiki(stikiId))

  const json = await destroyStiki(stikiId, destroyData, dispatch)
}

export const stikisSlice = createSlice({
  name: 'stikis',
  initialState,
  reducers: {
    loadStikisData: (state, action) => {
      const pages = action.payload.pages

      state.allIds = []
      state.byIds = {}

      pages.forEach((e, i) => {
        const stikis = e.page.stikis
        stikis.forEach((e, i) => {
          const id = e.stiki.id
          state.allIds.push(id)
          state.byIds[id] = {
            ...e.stiki,
            isSelected: false,
            isDirty: false,
          }
        })
      })
    },
    addStiki: (state, action) => {
      const stiki = action.payload.stiki
      const id = stiki.id

      state.allIds.push(id)
      state.byIds[id] = {
        id: stiki.id,
        text: stiki.text,
        top_edge: stiki.top_edge,
        left_edge: stiki.left_edge,
        height: stiki.height,
        width: stiki.width,
        color: stiki.color,
        z_index: stiki.z_index,
        page_id: stiki.page_id,
        isSelected: false || stiki.isSelected,
        isDirty: false || stiki.isDirty,
      }
      state.currentStiki = id
    },
    removeStiki: (state, action) => {
      const stikiId = action.payload
      const idx = state.allIds.indexOf(stikiId)
      state.allIds.splice(idx, 1)
      delete state.byIds[stikiId]
      if (state.currentStiki === stikiId) {
        state.currentStiki = null
      }
    },
    removeAllStikisForPage: (state, action) => {
      const pageId = action.payload
      const stikiIds = selectAllStikisOnPage(pageId)({ stikis: state })
      stikiIds.forEach((stikiId) => {
        const idx = state.allIds.indexOf(stikiId)
        state.allIds.splice(idx, 1)
        delete state.byIds[stikiId]
        if (state.currentStiki === stikiId) {
          state.currentStiki = null
        }
      })
    },
    setStikiSelected: (state, action) => {
      const stikiId = action.payload.stikiId
      const retainSelection = Boolean(action.payload.retainSelection)
      const stiki = state.byIds[stikiId]
      const pageId = stiki.page_id
      const allStikisOnPage = selectAllStikisOnPage(pageId)({
        stikis: state,
      })
      const maxZIndex =
        state.byIds[allStikisOnPage[allStikisOnPage.length - 1]].z_index

      if (!retainSelection) {
        // First deselect any currently selected stikis
        allStikisOnPage.forEach((sId) => {
          state.byIds[sId].isSelected = false
        })
      }

      stiki.isSelected = true

      if (stiki.z_index < maxZIndex) {
        stiki.z_index = maxZIndex + 1
        stiki.isDirty = true
      }

      state.currentStiki = stiki.id
    },
    setStikisInMarqueeSelected: (state, action) => {
      // console.log('setStikisInMarqueeSelected()')

      const pageId = action.payload.pageId
      const retainSelection = Boolean(action.payload.retainSelection)
      let { left, top, width, height } = action.payload.marquee

      // Deal with possible negative width and height
      if (width < 0) {
        left += width
        width = -width
      }

      if (height < 0) {
        top += height
        height = -height
      }

      const right = left + width
      const bottom = top + height

      const allStikisOnPage = selectAllStikisOnPage(pageId)({
        stikis: state,
      })

      // if (!retainSelection) {
      //   // First deselect any currently selected stikis
      //   allStikisOnPage.forEach((sId) => {
      //     state.byIds[sId].isSelected = false
      //   })
      // }

      let maxZIndex =
        state.byIds[allStikisOnPage[allStikisOnPage.length - 1]].z_index

      let lastSelected = state.currentStiki

      // Loop over stikis on page and detect if each one is within marquee
      allStikisOnPage.forEach((stikiId) => {
        const stiki = state.byIds[stikiId]

        const xOverlap =
          stiki.left_edge < right && stiki.left_edge + stiki.width > left
        const yOverlap =
          stiki.top_edge < bottom && stiki.top_edge + stiki.height > top

        if (xOverlap && yOverlap) {
          stiki.isSelected = true
          lastSelected = stikiId
          if (stiki.z_index < maxZIndex) {
            maxZIndex += 1
            stiki.z_index = maxZIndex
            stiki.isDirty = true
          }
        } else if (!retainSelection) {
          stiki.isSelected = false
        }
      })

      state.currentStiki = null

      if (state.byIds[lastSelected]?.isSelected) {
        state.currentStiki = lastSelected
      }
    },
    setStikiUnselected: (state, action) => {
      const stikiId = action.payload
      const pageId = state.byIds[stikiId].page_id

      if (state.byIds[stikiId].isSelected) {
        state.byIds[stikiId].isSelected = false
        if (state.currentStiki === stikiId) {
          state.currentStiki = null
        }

        // If there are any other selected stikis, choose
        // the front-most one and make that the current stiki
        const selectedStikiIds = selectAllSelectedStikisOnPage(pageId)({
          stikis: state,
        })

        if (selectedStikiIds.length > 0) {
          state.currentStiki = selectedStikiIds[selectedStikiIds.length - 1]
        } else {
          state.currentStiki = null
        }
      }
    },
    setAllStikisOnPageUnselected: (state, action) => {
      const pageId = action.payload
      state.allIds.forEach((stikiId) => {
        if (state.byIds[stikiId].page_id === pageId) {
          state.byIds[stikiId].isSelected = false
        }
      })
      state.currentStiki = null
    },
    setStikiContent: (state, action) => {
      const stikiId = action.payload.stiki_id
      const text = action.payload.text
      const stiki = state.byIds[stikiId]

      if (stiki.text !== text) {
        stiki.isDirty = true
      }
      stiki.text = text
    },
    addPositionToStikis: (state, action) => {
      const stikiIds = action.payload.stikiIds
      const position = action.payload.position

      stikiIds.forEach((stikiId) => {
        const stiki = state.byIds[stikiId]
        const newLeftEdge = clamp(
          stiki.left_edge + position.x,
          STIKI_MIN_LEFT_EDGE - stiki.width,
          STIKI_MAX_LEFT_EDGE
        )
        const newTopEdge = clamp(
          stiki.top_edge + position.y,
          STIKI_MIN_TOP_EDGE - stiki.height,
          STIKI_MAX_TOP_EDGE
        )
        if (stiki.left_edge !== newLeftEdge || stiki.top_edge !== newTopEdge) {
          stiki.left_edge = newLeftEdge
          stiki.top_edge = newTopEdge
          stiki.isDirty = true
        }
      })
    },
    alignStikisTo: (state, action) => {
      const stikiIds = action.payload.stikiIds
      const side = action.payload.side
      const position = action.payload.position

      stikiIds.forEach((stikiId) => {
        const stiki = state.byIds[stikiId]
        let newLeftEdge = stiki.left_edge
        let newTopEdge = stiki.top_edge

        if (side === 'LEFT') {
          newLeftEdge = position
        } else if (side === 'RIGHT') {
          newLeftEdge = position - stiki.width
        } else if (side === 'TOP') {
          newTopEdge = position
        } else if (side === 'BOTTOM') {
          newTopEdge = position - stiki.height
        }

        if (stiki.left_edge !== newLeftEdge || stiki.top_edge !== newTopEdge) {
          stiki.left_edge = newLeftEdge
          stiki.top_edge = newTopEdge
          stiki.isDirty = true
        }
      })
    },
    addSizeToStiki: (state, action) => {
      const stikiId = action.payload.stikiId
      const size = action.payload.size
      const stiki = state.byIds[stikiId]

      const newWidth = Math.max(STIKI_MIN_WIDTH, stiki.width + size.x)
      const newHeight = Math.max(STIKI_MIN_HEIGHT, stiki.height + size.y)

      if (stiki.width !== newWidth || stiki.height !== newHeight) {
        stiki.isDirty = true
        stiki.width = newWidth
        stiki.height = newHeight
      }
    },
    addSizeToStikis: (state, action) => {
      const stikiIds = action.payload.stikiIds
      const size = action.payload.size

      stikiIds.forEach((stikiId) => {
        const stiki = state.byIds[stikiId]

        const newWidth = Math.max(STIKI_MIN_WIDTH, stiki.width + size.x)
        const newHeight = Math.max(STIKI_MIN_HEIGHT, stiki.height + size.y)

        if (stiki.width !== newWidth || stiki.height !== newHeight) {
          stiki.isDirty = true
          stiki.width = newWidth
          stiki.height = newHeight
        }
      })
    },
    setStikiColor: (state, action) => {
      // console.log('setStikiColor() action.payload')
      const stikiId = action.payload.stikiId
      const color = action.payload.color
      const stiki = state.byIds[stikiId]

      if (stiki.color !== color) {
        stiki.isDirty = true
        stiki.color = color
      }
    },
    setStikiDirty: (state, action) => {
      const stikiId = action.payload
      state.byIds[stikiId].isDirty = true
    },
    setStikisDirty: (state, action) => {
      const stikiIds = action.payload
      stikiIds.forEach((stikiId) => {
        state.byIds[stikiId].isDirty = true
      })
    },
    setStikiClean: (state, action) => {
      const stikiId = action.payload
      state.byIds[stikiId].isDirty = false
    },
  },
  extraReducers: {},
})

export const {
  loadStikisData,
  addStiki,
  removeStiki,
  removeAllStikisForPage,
  setStikiSelected,
  setStikisInMarqueeSelected,
  setStikiUnselected,
  setAllStikisOnPageUnselected,
  setStikiContent,
  addPositionToStikis,
  alignStikisTo,
  addSizeToStiki,
  addSizeToStikis,
  setStikiColor,
  setStikiDirty,
  setStikisDirty,
  setStikiClean,
} = stikisSlice.actions

export const selectAllStikis = (state) => state.page.stikis.allIds

export default stikisSlice.reducer

export const selectAllStikisOnPage = (id) => (state) => {
  return state.stikis.allIds
    .filter((stikiId) => state.stikis.byIds[stikiId].page_id === id)
    .sort(
      (a, b) => state.stikis.byIds[a].z_index - state.stikis.byIds[b].z_index
    )
}

export const selectAllSelectedStikisOnPage = (id) => (state) => {
  return state.stikis.allIds
    .filter(
      (stikiId) =>
        state.stikis.byIds[stikiId].page_id === id &&
        state.stikis.byIds[stikiId].isSelected
    )
    .sort(
      (a, b) => state.stikis.byIds[a].z_index - state.stikis.byIds[b].z_index
    )
}

export const selectAllUnselectedStikisOnPage = (id) => (state) => {
  return state.stikis.allIds
    .filter(
      (stikiId) =>
        state.stikis.byIds[stikiId].page_id === id &&
        !state.stikis.byIds[stikiId].isSelected
    )
    .sort(
      (a, b) => state.stikis.byIds[a].z_index - state.stikis.byIds[b].z_index
    )
}

export const selectDirtyStikisOnPage = (pageId) => (state) => {
  return state.stikis.allIds.filter(
    (stikiId) =>
      state.stikis.byIds[stikiId].page_id === pageId &&
      state.stikis.byIds[stikiId].isDirty
  )
}

export const selectStiki = (id) => (state) => {
  return state.stikis?.byIds[id] || null
}

export const selectCurrentStikiId = (state) => {
  return state.stikis?.currentStiki
}
