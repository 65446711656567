import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'

import pagesReducer from '../redux/features/pages/pagesSlice'
import stikisReducer from '../redux/features/stikis/stikisSlice'
import uiReducer from '../redux/features/ui/uiSlice'

export const history = createBrowserHistory()
const router = connectRouter(history)

export default configureStore({
  reducer: {
    pages: pagesReducer,
    stikis: stikisReducer,
    ui: uiReducer,
    router,
  },
})
