import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setPageTitle,
  updatePageAsync,
  selectPageTitle,
} from '../redux/features/pages/pagesSlice'

function PageTitleContainer(props) {
  const pageId = props.pageId
  const pageTitle = useSelector(selectPageTitle(pageId))

  const dispatch = useDispatch()

  const [title, setTitle] = useState(pageTitle)
  const [isEditing, setIsEditing] = useState(false)

  const handleRenameClick = (e) => {
    // console.log('handleRenameClick()')
    setIsEditing(true)
  }

  const handleCancelClick = (e) => {
    // console.log('handleCancelClick()')
    setIsEditing(false)
    setTitle(pageTitle)
  }

  const handleSaveClick = (e) => {
    dispatch(setPageTitle({ pageId, title }))
    dispatch(updatePageAsync(pageId))
    setIsEditing(false)
  }

  const handleInputChange = (e) => {
    setTitle(e.target.value)
  }

  return (
    <div id='pageTitleContainer' className={isEditing ? 'editing' : ''}>
      <div id='pageTitleWrapper'>
        <div id='pageTitle' className='h2'>
          {title}
        </div>
        <input
          id='pageTitleText'
          type='text'
          value={title}
          onChange={handleInputChange}
        ></input>
        <button
          id='editPageTitle'
          className='textButton'
          title='Rename page'
          onClick={handleRenameClick}
        >
          Rename
        </button>
        <button
          id='cancelPageTitle'
          className='textButton'
          title='Cancel changes'
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button
          id='savePageTitle'
          className='textButton'
          title='Save changes'
          onClick={handleSaveClick}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default PageTitleContainer
