import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectPage } from '../redux/features/pages/pagesSlice'
import { createStikiAsync } from '../redux/features/stikis/stikisSlice'
import { selectUICurrentColor } from '../redux/features/ui/uiSlice'

import { clamp } from '../redux/helpers'

import {
  STIKI_DEFAULT_WIDTH,
  STIKI_MAX_LEFT_EDGE,
  STIKI_MAX_TOP_EDGE,
  STIKI_MIN_HEIGHT,
  STIKI_MIN_LEFT_EDGE,
  STIKI_MIN_TOP_EDGE,
} from '../redux/constants'

function jitter(amount) {
  var direction = Math.round(Math.random()) * 2 - 1
  return direction * (amount * Math.random())
}

export default function AddStiki(props) {
  const pageId = props.pageId
  const page = useSelector(selectPage(pageId))

  const currentColor = useSelector(selectUICurrentColor)

  const dispatch = useDispatch()
  const [text, setText] = useState('')

  const testHeight = useRef(null)

  const stikiData = () => {
    const left_edge = clamp(
      jitter(20) - STIKI_DEFAULT_WIDTH / 2 - page.left_edge,
      STIKI_MIN_LEFT_EDGE,
      STIKI_MAX_LEFT_EDGE
    )
    const top_edge = clamp(
      jitter(2) - page.top_edge,
      STIKI_MIN_TOP_EDGE,
      STIKI_MAX_TOP_EDGE
    )

    // Get height of testText and divide by 18 to get number of lines.
    // Add 1.
    // Add 2 for margin.
    // Allow for 1.231 line-height
    // Not less than min height.
    let { height } = testHeight.current.getBoundingClientRect()
    height = 1.231 * (height / 18 - 1) + 3
    height = Math.max(STIKI_MIN_HEIGHT, height)
    // console.log('height:', height)

    return {
      page_id: pageId,
      stiki: {
        text,
        left_edge,
        top_edge,
        width: STIKI_DEFAULT_WIDTH,
        height,
        z_index: 'front',
        color: currentColor,
      },
    }
  }

  const handleChange = (e) => {
    // console.log('<AddStiki />.handleChange()')
    setText(e.target.value)
  }

  const handleClick = (e) => {
    // console.log('<AddStiki />.handleClick()')
    setText('')
    dispatch(createStikiAsync(stikiData()))
  }

  return (
    <div id='createStiki' className='block'>
      <input
        id='createStikiText'
        type='text'
        value={text}
        placeholder='Type text for stiki...'
        className='grey6'
        style={{ backgroundColor: currentColor }}
        onChange={(e) => handleChange(e)}
      ></input>
      <span> </span>
      <button
        id='submitCreateStiki'
        className='f14'
        aria-label='Create stiki'
        onClick={(e) => handleClick(e)}
      >
        Create
      </button>
      <div className='testHeight' ref={testHeight}>
        {text}
      </div>
    </div>
  )
}
