import React from 'react'
import { useSelector } from 'react-redux'

import Navigation from '../components/Navigation'
import EmptyTitleContainer from '../components/EmptyTitleContainer'
import PageTitleContainer from '../components/PageTitleContainer'
import StikisLogoTiny from 'images/stikis_logo-img_only-small.png'
import { selectPage } from '../redux/features/pages/pagesSlice'

function TopMenu(props) {
  const pageId = props.pageId
  const page = useSelector(selectPage(pageId))

  return (
    <div id='topMenu' className='menuStrip'>
      <div id='topMenuContents' className=''>
        <div id='stikisLogo' className=''>
          <a href='/accounts/welcome' className='noDecoration'>
            <img
              src={StikisLogoTiny}
              id='stikisLogoTiny'
              className='png'
              alt='Stikis logo'
            />
          </a>
        </div>
        <Navigation />
        {Boolean(page) ? (
          <PageTitleContainer key={pageId} pageId={pageId} />
        ) : (
          <EmptyTitleContainer />
        )}
      </div>
      <div className='topShadow'></div>
    </div>
  )
}

export default TopMenu

/* 
<div id="flash_messages_page">
    <% flash.each do |type, msg| %>
      <div className="<%= type %>">
        <%= msg %>
      </div>
    <% end %>
  </div>
*/
