import { savePending, saveFulfilled, saveRejected } from './features/ui/uiSlice'

const responseHandler = (dispatch) => (response) => {
  {
    // console.log(
    //   'responseHandler() response:',
    //   response,
    //   'response.status:',
    //   response.status,
    //   'response.body:',
    //   response.body
    // )
    if (response.status >= 400) {
      dispatch(
        saveRejected({ error: `${response.status} - ${response.statusText}` })
      )
      return
    }

    return response.json().then(
      (json) => {
        // console.log('responseHandler() response.json:', json)
        if (json.status >= 400) {
          dispatch(saveRejected(json))
          return
        }
        dispatch(saveFulfilled())
        return json
      },
      (error) => {
        // console.log('responseHandler() response.error:', error)
        return
      }
    )
  }
}

const errorHandler = (dispatch) => (error) => {
  dispatch(saveRejected())
  // console.log(`${addr} An error occurred`, error)
}

export const get = function (addr, dispatch) {
  // console.log('client.get() addr:', addr, 'dispatch:', dispatch)
  dispatch(savePending())
  return fetch(addr).then(responseHandler(dispatch), errorHandler(dispatch))
}

export const post = function (addr, data, dispatch) {
  dispatch(savePending())
  return fetch(addr, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(responseHandler(dispatch), errorHandler(dispatch))
}

export const put = function (addr, data, dispatch) {
  // console.log('client.put() addr:', addr, 'data:', data, 'dispatch:', dispatch)
  dispatch(savePending())
  return fetch(addr, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(responseHandler(dispatch), errorHandler(dispatch))
}

export const destroy = function (addr, data, dispatch) {
  dispatch(savePending())
  return fetch(addr, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(responseHandler(dispatch), errorHandler(dispatch))
}
