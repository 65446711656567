export const STIKI_DEFAULT_COLOR = '#F0E68C'
export const STIKI_HALO_BORDER = 4
export const STIKI_DEFAULT_LEFT_EDGE = 0
export const STIKI_DEFAULT_TOP_EDGE = 4
export const STIKI_DEFAULT_WIDTH = 15
export const STIKI_DEFAULT_HEIGHT = 12
export const STIKI_DEFAULT_Z_INDEX = 0
export const STIKI_MIN_LEFT_EDGE = -213
export const STIKI_MAX_LEFT_EDGE = 213
export const STIKI_MIN_TOP_EDGE = -151.5
export const STIKI_MAX_TOP_EDGE = 151.5
export const STIKI_MIN_WIDTH = 3.22
export const STIKI_MIN_HEIGHT = 3.22

export const PAGE_DEFAULT_TITLE = 'New page'
export const PAGE_DEFAULT_LEFT_EDGE = 0
export const PAGE_DEFAULT_TOP_EDGE = 0
export const PAGE_DEFAULT_ZOOM = 1.0
export const PAGE_MIN_LEFT_EDGE = -210
export const PAGE_MAX_LEFT_EDGE = 210
export const PAGE_MIN_TOP_EDGE = -148.5
export const PAGE_MAX_TOP_EDGE = 148.5
export const PAGE_ZOOM_PRESETS = [0.1, 0.2, 0.5, 1, 2, 5]
export const PAGE_BASE_FONT_SIZE = 14

export const SELECTION_NUDGE_DIST = 4
export const PAGE_NUDGE_DIST = 16
export const PAGE_SHIFT_NUDGE_DIST = 32
