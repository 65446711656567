import React from 'react'
import { useSelector } from 'react-redux'
import { selectNetwork } from '../redux/features/ui/uiSlice'

export default function SavingIndicator() {
  const network = useSelector(selectNetwork)

  const text = network.hasError
    ? `Error: ${network.errorMessage}`
    : network.isFetching
    ? 'Saving'
    : 'Saved'

  return (
    <li id='savingIndicator' className='pageMenuItem'>
      {text}
    </li>
  )
}
