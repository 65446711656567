import React from 'react'

function EmptyTitleContainer(props) {
  return (
    <div id='pageTitleContainer' className=''>
      <div id='pageTitleWrapper'>
        <div id='pageTitle' className='h2'>
          <div
            className='h1 block'
            style={{ position: 'relative', top: '-2px' }}
          >
            stikis
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyTitleContainer
